import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import { CustomGutterSpaceRow, FullWidth, MaxWidthLayout } from 'components/Layout/Layout.styles'
import dynamic from 'next/dynamic'
import { FunctionComponent, useEffect, useState } from 'react'
import { getWelcomeState } from 'utils/utils'
import { MobileHomePage, WelcomeStateEnum } from 'interfaces/homePage'
import Header from 'components/Header'
import { useCustomUser } from 'contexts/CustomUserProvider'
import useAppTour from 'hooks/useAppTour'
import { TourPath } from 'interfaces/appTour'
import HomeLandingFooter from 'components/HomeLanding/HomeLandingFooter'
import { useCookies } from 'react-cookie'
import { API_HEADER_COOKIES } from 'constant'
import FirstFoldCarousel from './FirstFoldCarousel'
import HomePageWidgets from './HomePageWidgets'
import LatestSalesListing from 'components/LatestSaleWidget/LatestSaleWidget'
// import ArcadeGameNudge from 'components/Arcade/ArcadeGameNudge'

const NavigationBar = dynamic(() => import('components/NavigationBar'))
// const StickyFloatingButton = dynamic(() => import('components/StickyFloatingButton'))
const HowRarioWorksCard = dynamic(() => import('components/HowRarioWorksCard'))
const LearnAboutD3 = dynamic(() => import('components/LearnAboutD3'))
const AppTour = dynamic(() => import('components/AppTour'), { ssr: false })
// const PracticeMatchBanner = dynamic(() => import('components/WelcomeScreen/PracticeMatchBanner'))

const HomePage: FunctionComponent<{
  homePageData: MobileHomePage
  showAppDownloadStrip?: boolean
}> = ({ homePageData /* showAppDownloadStrip = false */ }) => {
  const { user: userData } = useCustomUser()

  const showAppTour = useAppTour(TourPath.HOME)
  const welcomeState = getWelcomeState(userData)
  const [cookies] = useCookies(['is_practice_banner_skipped', API_HEADER_COOKIES.APP_PLATFORM])
  const [, setShowPracticeBanner] = useState<boolean>(false)
  const showPracticeBannerCallback = (showBanner: boolean) => {
    if (
      showBanner &&
      cookies.is_practice_banner_skipped !== 'true' &&
      welcomeState === WelcomeStateEnum.NEW_USER
    ) {
      setShowPracticeBanner(true)
    } else {
      setShowPracticeBanner(false)
    }
  }

  useEffect(() => {
    trackEvent('Section Viewed', 'track', {
      sub_category: 'homepage-special_app_hero_cards',
      type: 'carousel_cards',
      view_type: 'carousel_cards',
      category: 'homepage',
    })

    // To trigger scroll handler on load
    document.documentElement.scrollTo({ top: 1, behavior: 'smooth' })
  }, [])

  return (
    <>
      {/* {showAppDownloadStrip && PLATFORM_ORIGIN === ThemeEnum.RARIO && (
        <FlexBox
          position={'relative'}
          cursor={'pointer'}
          height={'auto'}
          width={'100%'}
          maxWidth={theme.maxDesktopLayoutWidth}
          mx={'auto'}
          onClick={() => window && window.open(RARIO_WEBVIEW_APK_URL, '_parent')}
        >
          <Link href={'/app-download#installationSteps'} scroll={false}>
            <Image
              src={getCDNUrl('/App/images/rario-landing/homepage-download-strip.png', true)}
              height={'100%'}
              width={'100%'}
              name={'App Download Strip'}
            />
          </Link>
        </FlexBox>
      )} */}
      <FlexBox
        position={'absolute'}
        flexDirection="column"
        width="100%"
        maxWidth={['100%', theme.maxDesktopLayoutWidth]}
        top={'0px'}
      >
        <Header />
      </FlexBox>

      <MaxWidthLayout pt={0}>
        <FirstFoldCarousel data={homePageData} />

        {/* <StickyFloatingButton /> */}

        <NavigationBar />

        <LatestSalesListing />
        {/* <FlexBox flexDirection={'column'} width={'100%'} justifyContent={'center'}>
          {PLATFORM_ORIGIN === ThemeEnum.RARIO && <D3TrialGame />}
          <PlayBigWinBig />
        </FlexBox> */}
        {
          // Not Required
          /* <ArcadeGameNudge /> */
        }
        <FullWidth>
          <HomePageWidgets />
        </FullWidth>
      </MaxWidthLayout>

      <HowRarioWorksCard />
      <LearnAboutD3 />

      <CustomGutterSpaceRow flexDirection={'column'} margin="0 20px 10px">
        <TextField
          textTransform="uppercase"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          color={theme.colors.customWhite}
          fontWeight={800}
          fontSize={'40px'}
          lineHeight={'42px'}
        >
          INVEST IN CRICKETERS
        </TextField>
        <TextField
          textTransform="uppercase"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          color={theme.colors.customWhite}
          fontWeight={800}
          fontSize={'20px'}
          lineHeight={'32px'}
        >
          WIN BIG REWARDS
        </TextField>
      </CustomGutterSpaceRow>
      <FlexBox mb={'100px'}>
        <HomeLandingFooter showSocialLinks={true} />
      </FlexBox>
      {showAppTour && (
        <AppTour pathname={TourPath.HOME} showPracticeBannerCallback={showPracticeBannerCallback} />
      )}
      {/* {!mobileView && PLATFORM_ORIGIN === ThemeEnum.RARIO && <DownloadBanner />} */}
      {/* {showPracticeBanner && (
        <PracticeMatchBanner showPracticeBannerCallback={showPracticeBannerCallback} />
      )} */}
    </>
  )
}

export default HomePage
